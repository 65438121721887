<template>
  <div
    ref="rootElement"
    class="multiview-events-dropdown"
  >
    <div class="multiview-events-dropdown__search">
      <Icon
        class="multiview-events-dropdown__search-icon"
        name="search"
      />
      <input
        :value="search"
        @input="onSearch"
        class="multiview-events-dropdown__search-field"
        type="text"
        :placeholder="placeholder"
        @focus="openMenu"
      >
    </div>
    <div
      v-if="isMenuOpen"
      class="multiview-events-dropdown__menu"
    >
      <div
        v-if="isLoading"
        class="multiview-events-dropdown__menu-message"
      >
        <Spinner
          small
        />
        Finding events...
      </div>
      <div
        v-else-if="!events?.length"
        class="multiview-events-dropdown__menu-message"
      >
        No events found.
      </div>
      <div
        v-for="event in events"
        v-show="!isLoading"
        :key="event.eventId"
        :class="[
          'multiview-events-dropdown__menu-item',
          { 'multiview-events-dropdown__menu-item--is-disabled': isEventInList(event.eventId) },
        ]"
        @click="addEventToList(event.eventId)"
      >
        <div class="multiview-events-dropdown__event">
          <div class="multiview-events-dropdown__event-heading">
            {{ formatEventName(event.eventName, event.isUsaView) }}
          </div>
          <div class="multiview-events-dropdown__event-subheading">
            {{ formatEventStartTime(event.startsAt) }}
            &bull;
            {{ event.competition?.competitionName || 'N/A' }}
          </div>
        </div>
        <div class="multiview-events-dropdown__fake-button">
          {{ formatFakeButtonLabel(event.eventId) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { onClickOutside } from '@vueuse/core';
import {
  split,
  debounce,
  reverse,
  join,
  pickBy,
  identity,
  isArray,
  set,
  replace,
  includes,
} from 'lodash';
import { addHours, subHours } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { fetchMultiviewSearchResults } from '@/services/api';
import Icon from '@/components/common/Icon';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    Icon,
    Spinner,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const rootElement = ref(null);
    const isMenuOpen = ref(false);
    const isLoading = ref(false);
    const events = ref([]);
    const search = ref('');
    const placeholder = computed(() => 'Add “New York Knicks”...');

    const loadEvents = debounce(() => {
      events.value = [];
      isLoading.value = true;

      const queryOptions = {
        filter: {
          sport: { limbo: { equalTo: false } },
          eventIsCoveredsByEventId: {
            some: {
              available: {
                equalTo: true,
              },
            },
          },
        },
        condition: {},
      };

      let startDate = new Date();
      const currentDate = new Date();
      const timezoneHoursOffset = currentDate.getTimezoneOffset() / 60;
      if (timezoneHoursOffset >= 0) {
        startDate = addHours(startDate, timezoneHoursOffset - 5);
      } else {
        startDate = subHours(startDate, (Math.abs(timezoneHoursOffset) + 5));
      }
      set(queryOptions, 'filter.startsAt.greaterThanOrEqualTo', format(startDate, 'yyyy-LL-dd\'T\'HH:mm:ss'));

      if (search.value) {
        set(queryOptions, 'orderBy', 'STARTS_AT_ASC');
        set(queryOptions, 'condition.eventNameSimilar', search.value);
      }

      fetchMultiviewSearchResults(pickBy(queryOptions, identity))
        .then((response) => {
          events.value = response.data?.events?.nodes || [];
        })
        .catch((error) => {
          console.error(error);
          events.value = [];
        })
        .finally(() => {
          isLoading.value = false;
        });
    }, 300);
    const openMenu = () => {
      if (isMenuOpen.value) return;
      isMenuOpen.value = true;
      isLoading.value = true;
      loadEvents();
    };
    const closeMenu = () => {
      isMenuOpen.value = false;
      isLoading.value = false;
      events.value = [];
      search.value = null;
      search.value = '';
    };
    const onSearch = (event) => {
      search.value = event.target.value;
      loadEvents();
    };
    const formatEventName = (eventName, isUsaView) => {
      const competitors = split(eventName, ' v ');
      if (isUsaView) return join(reverse(competitors), ' @ ');
      return join(competitors, ' vs ');
    };
    const formatEventStartTime = (eventStartsAt) => {
      const formattedStartsAt = format(zonedTimeToUtc(eventStartsAt, 'UTC'), 'MMM d yyyy, hh:mm a');
      return replace(formattedStartsAt, ` ${new Date().getFullYear()}`, '');
    };
    const isEventInList = (eventId) => {
      let ids = route.query.ids || [];
      if (!isArray(ids)) ids = [ids];
      return includes(ids, eventId);
    };
    const formatFakeButtonLabel = (eventId) => (isEventInList(eventId) ? 'Already added' : 'Add event');
    const addEventToList = (eventId) => {
      if (isEventInList(eventId)) return;

      let ids = route.query.ids || [];
      if (!isArray(ids)) ids = [ids];
      ids = [...ids, eventId];

      router.replace({
        name: route.name,
        query: pickBy({
          ...route.query,
          ids,
        }, identity),
      });
    };

    onClickOutside(rootElement, closeMenu);

    return {
      rootElement,
      isMenuOpen,
      isLoading,
      events,
      search,
      placeholder,
      openMenu,
      closeMenu,
      onSearch,
      formatEventName,
      formatEventStartTime,
      isEventInList,
      formatFakeButtonLabel,
      addEventToList,
    };
  },
};
</script>

<style lang="scss">
.multiview-events-dropdown {
  position: relative;

  &__search {
    display: flex;
    width: 450px;
    height: 32px;
    min-width: var(--width-large, 44px);
    flex-direction: row;
    align-items: center;
    position: relative;

    &-icon {
      position: absolute;
      left: 8px;
      width: 16px;
      height: 16px;
      stroke: #A9A9A9;
    }

    &-field {
      width: 100%;
      padding: 8px 8px 8px 32px;
      border-radius: var(--radius-medium, 4px);
      border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
      background: var(--neutral-bg-default-resting, #FFF);
      height: 32px;
      font-size: 14px;

      &::placeholder {
        color: #CDCDCD;
      }

      &:focus {
        box-shadow: 0px 0px 0px 2px #A2BFFF;
        outline: none;
      }
    }
  }

  &__menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    overflow-y: auto;
    z-index: 9000;
    display: flex;
    flex-direction: column;
    border-radius: var(--radius-medium, 4px);
    border: 1px solid var(--neutral-stroke-default-resting, #F0F0F0);
    background: var(--neutral-bg-default-resting, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(25, 20, 20, 0.08);
    margin-top: 4px;
    padding: 4px 0;
    user-select: none;

    &-message {
      display: flex;
      align-items: center;
      padding: 8px;
      gap: 8px;
      background: var(--neutral-bg-default-resting, #FFF);
      overflow: hidden;
      color: var(--neutral-text-default-resting, #191414);
      text-overflow: ellipsis;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
      width: 100%;
      flex: 1;
      cursor: default;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      gap: 8px;
      background: var(--neutral-bg-default-resting, #FFF);
      overflow: hidden;
      color: var(--neutral-text-default-resting, #191414);
      text-overflow: ellipsis;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
      width: 100%;
      flex: 1;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: #FAFAFA;
        outline: none;
      }

      &--is-disabled {
        cursor: not-allowed;

        &:hover,
        &:focus {
          background-color: #FFF;
        }

        .multiview-events-dropdown__event-heading,
        .multiview-events-dropdown__event-subheading,
        .multiview-events-dropdown__fake-button {
          color: #A9A9A9;

          .icon svg path {
            stroke: #A9A9A9;
          }
        }
      }
    }
  }

  &__event {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    overflow: hidden;

    &-heading {
      font-size: 14px;
      color: #191414;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-subheading {
      font-size: 12px;
      color: #807f7f;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-metadata {
      display: flex;
      align-items: center;
      gap: 4px;
      color: #A9A9A9;
    }
  }

  &__fake-button {
    font-size: 12px;
    flex-shrink: 0;
    color: #191414;
  }
}
</style>
